export const navBar = [
  {
    href: "/latest",
    title: "Mới nhất",
    key: 'latest'
  },
  {
    href: "#",
    title: "Danh mục",
    key: 'sections'
  },
  {
    href: "/partner",
    title: "Đối tác",
    key: 'partner'
  },
  {
    href: "/contact",
    title: "Liên lạc",
    key: 'contact'
  },
];