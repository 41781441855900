export const VIEW_TYPE = {
  lg: 'lg',
  sm: 'sm',
  thumbLg: 'thumb_lg',
  thumbSm: 'thumb_sm',
}

export const SIZE_HEIGHT_VIEW_TYPE = {
  lg: 250,
  sm: 70,
  thumbLg: 250,
  thumbSm: 250,
}