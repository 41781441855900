export const cateList = [
  {
    id: 0,
    key: "lastest",
    title: "Mới nhất",
    href: "lastest",
  },
  {
    id: 1,
    key: "rising",
    title: "Nổi bật",
    href: "rising"
  },
  {
    id: 2,
    key: "popular",
    title: "Phổ biến",
    href: "popular"
  },
  {
    id: 3,
    key: "commented",
    title: "Bình luận",
    href: "commented"
  },
];